import api from 'App/api'
import urls from 'App/utils/urls'
import profilesTypes from 'App/enums/profilesTypes'

const isDevelopmentMode = process.env.REACT_APP_MODE !== 'production'

async function getUserData() {
  const { data } = await api.get(`${urls.AUTH_API}/v1/users/me`)
  const [profile] = data.profiles

  return {
    profileId: profile.id,
  }
}

function getIsValidAccessProfile(profileId) {
  const allowedProfiles = new Set([
    profilesTypes.school,
    profilesTypes.coordinator,
    profilesTypes.teacher,
    profilesTypes.consultant,
    profilesTypes.financialManager,
    profilesTypes.orderStore,
    profilesTypes.schoolAdministration,
  ])

  return allowedProfiles.has(profileId)
}

async function getIsAllowedAccess() {
  const { profileId } = await getUserData()
  const isValidAccessProfile = getIsValidAccessProfile(profileId)

  return isDevelopmentMode ? true : isValidAccessProfile
}

export default getIsAllowedAccess
